import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";

const Commodities = ({
  handleTradeChart,
  CommoditiesData,
  CommoditiesLoading,
}) => {
  // const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //     fetch(`${process.env.REACT_APP_API}/api/user/trade/market/commodities/view`, {
  //         method: 'GET',
  //     })
  //         .then(res => res.json())
  //         .then(data => {
  //           setData(data.data);
  //           setLoading(false);
  //         });
  // }, [])

  if (CommoditiesLoading) {
    return;
  }
  return (
    <>
      <div className="dashboard-trade-market-area  h-700 overflow-auto element">
        {CommoditiesData?.length !== 0 ? (
          CommoditiesData.map((data, index) => {
            if (data) {
              return (
                <SingleItem
                  data={data}
                  index={index}
                  key={data._id}
                  handleTradeChart={handleTradeChart}
                ></SingleItem>
              );
            }
          })
        ) : (
          <>
            <div className="text-center h-50">
              <h6> Data not found</h6>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Commodities;
