import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import "./KYC.css";
import uploadImg from "./upload.png";
const KYC = () => {
  const { authUser } = useContext(AuthContext);

  const [userfront_page_img, setUserfront_page_img] = useState("");
  const [userback_page_img, setUserback_page_img] = useState("");
  const refSubmitDis = useRef();
  const [errorMessage, setErrorMessage] = useState([]);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const [results, setResults] = useState({});

  const [userfront_page_imgShow, setUserfront_page_imgShow] = useState("");
  const [userback_page_imgShow, setUserback_page_imgShow] = useState("");

  const userfront_page_imgOnLoad = (fileString) => {
    setUserfront_page_imgShow(fileString);
  };

  const userfront_page_imgGetBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      userfront_page_imgOnLoad(reader.result);
    };
  };

  const userback_page_imgnOnLoad = (fileString) => {
    setUserback_page_imgShow(fileString);
  };

  const userback_page_imgGetBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      userback_page_imgnOnLoad(reader.result);
    };
  };

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/kyc/verify/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          setData(data.data);
        });
    }
  }, [results, authUser?._id]);

  const handlefront_page_img = (e) => {
    setErrorMessage("");
    const file = e.target.files[0];
    setUserfront_page_img(file);
    userfront_page_imgGetBase64(file);
  };
  const handleback_page_img = (e) => {
    setErrorMessage("");
    const file = e.target.files[0];
    setUserback_page_img(file);
    userback_page_imgGetBase64(file);
  };

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    setErrorMessage("");
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    const storeData = {
      ...InpuData,
      user_id: authUser?._id,
      front_page_img: userfront_page_img,
      back_page_img: userback_page_img,
      name:`${authUser?.fname} ${authUser?.lname}`,
    };

    if (storeData.front_page_img === "") {
      setErrorMessage({
        front_page_img: { msg: "please choose Front part image" },
      });
      refSubmitDis.current.removeAttribute("disabled");
    } else if (storeData.back_page_img === "") {
      setErrorMessage({
        back_page_img: { msg: "please choose Back part image" },
      });
      refSubmitDis.current.removeAttribute("disabled");
    } else {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API}/api/kyc/verify/store`,
          storeData,
          config
        )
        .then((data) => {
          event.target.reset();
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setResults(data);
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          refSubmitDis.current.removeAttribute("disabled");
          if (error?.response?.data?.errors) {
            setErrorMessage(error?.response?.data?.errors);
          } else {
            setErrorMessage(error?.response?.data);
          }
        });
    }
  };

  const [userDataLoading, setUserDataLoading] = useState(true);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/auth/view/single/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
          setUserDataLoading(false);
        });
    }
  }, [authUser?._id]);
  const [KYCType, setKYCType] = useState("");
  if (loading || userDataLoading) {
    return;
  }

  if (data?.status === 0) {
    return (
      <>
        <div className="user-deposit-section">
          <div className="container">
            <div className="card h-100">
              <div className="card-body">
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mb-3 text-primary text-center">
                      Pending Real name verification
                    </h6>
                    <p>
                      Name : <span>{data?.user_name}</span>
                    </p>
                    <p>
                      Certification : <span>{data?.type}</span>
                    </p>
                    {data?.number && (
                      <p>
                        {data?.type} Number:
                        <span>
                          {String(data?.number).length > 4
                            ? `${String(data?.number).slice(0, 2)}****${String(
                                data?.number
                              ).slice(
                                String(data?.number).length - 2,
                                String(data?.number).length
                              )}`
                            : `${String(data?.number).slice(0, 1)}****${String(
                                data?.number
                              ).slice(
                                String(data?.number).length - 1,
                                String(data?.number).length
                              )}`}
                        </span>
                      </p>
                    )}

                    {data?.ssn && (
                      <p>
                        SSN : <span>{data?.ssn}</span>
                      </p>
                    )}
                    <br />
                    <img
                      src={`${process.env.REACT_APP_API}/${data?.front_img}`}
                      alt=""
                    />
                    <br />
                    <img
                      src={`${process.env.REACT_APP_API}/${data?.back_img}`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (data?.status === 1 && userData?.kv) {
    return (
      <>
        <div className="user-deposit-section">
          <div className="container">
            <div className="card h-100">
              <div className="card-body">
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mb-3 text-primary">Real name verified</h6>
                    <p>
                      Name : <span>{data?.user_name}</span>
                    </p>
                    <p>
                      Certification : <span>{data?.type}</span>
                    </p>
                    {data?.number && (
                      <p>
                        {data?.type} Number:
                        <span>
                          {String(data?.number).length > 4
                            ? `${String(data?.number).slice(0, 2)}****${String(
                                data?.number
                              ).slice(
                                String(data?.number).length - 2,
                                String(data?.number).length
                              )}`
                            : `${String(data?.number).slice(0, 1)}****${String(
                                data?.number
                              ).slice(
                                String(data?.number).length - 1,
                                String(data?.number).length
                              )}`}
                        </span>
                      </p>
                    )}

                    {data?.ssn && (
                      <p>
                        SSN : <span>{data?.ssn}</span>
                      </p>
                    )}
                    <br />
                    <img
                      src={`${process.env.REACT_APP_API}/${data?.front_img}`}
                      alt=""
                    />
                    <br />
                    <img
                      src={`${process.env.REACT_APP_API}/${data?.back_img}`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="user-deposit-section">
          <div className="container">
            <div className="card h-100">
              <div className="card-body">
                <form onSubmit={handleSubmitData}>
                  <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 className="mb-3 text-primary">
                        Real name verification
                      </h6>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Certification
                        </label>
                        <select
                          class="form-control cmn--form--control"
                          name="type"
                          onClick={(e) => setKYCType(e.target.value)}
                        >
                          <option value="" disabled selected>
                            Select One
                          </option>
                          <option value="Passport">Passport</option>
                          <option value="NID">NID</option>
                          <option value="Driving License">
                            Driving License
                          </option>
                        </select>
                        <span className="text-danger pt-2">
                          {errorMessage?.type && errorMessage?.type?.msg}
                        </span>
                      </div>
                    </div>
                    {KYCType && (
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                        <div className="form-group">
                          <label
                            htmlFor="exampleFormControlInput2"
                            className="form-label"
                          >
                            {KYCType} Number
                          </label>
                          <input
                            type="text"
                            name="number"
                            className="form-control"
                            id="exampleFormControlInput2"
                            aria-describedby="emailHelp"
                          />
                          <span className="text-danger pt-2">
                            {errorMessage?.number && (
                              <>
                                {KYCType} {errorMessage?.number?.msg}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Front Part Photo
                        </label>
                        <br />
                        <div className="image-parent">
                          <div className="image-file-upload">
                            {userfront_page_imgShow ? (
                              <img
                                src={userfront_page_imgShow}
                                alt="upload"
                                className="user-upload-img"
                              />
                            ) : (
                              <img
                                src={uploadImg}
                                alt="upload"
                                className="upload-img"
                              />
                            )}

                            <input
                              type="file"
                              onChange={handlefront_page_img}
                              accept=" .jpg,  .jpeg,  .png, "
                            />
                          </div>
                        </div>
                        <br />
                        <span className="text-danger pt-2">
                          {errorMessage?.front_page_img &&
                            errorMessage?.front_page_img?.msg}
                        </span>
                        <pre className="text--base mt-1">
                          Supported mimes: jpg,jpeg,png
                        </pre>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Back Part Photo
                        </label>
                        <br />
                        <div className="image-parent">
                          <div className="image-file-upload">
                            {userback_page_imgShow ? (
                              <img
                                src={userback_page_imgShow}
                                alt="upload"
                                className="user-upload-img"
                              />
                            ) : (
                              <img
                                src={uploadImg}
                                alt="upload"
                                className="upload-img"
                              />
                            )}

                            <input
                              type="file"
                              onChange={handleback_page_img}
                              accept=" .jpg,  .jpeg,  .png, "
                            />
                          </div>
                        </div>
                        <br />
                        <span className="text-danger pt-2">
                          {errorMessage?.back_page_img &&
                            errorMessage?.back_page_img?.msg}
                        </span>
                        <pre className="text--base mt-1">
                          Supported mimes: jpg,jpeg,png
                        </pre>
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          SSN <span>(optional)</span>
                        </label>
                        <input
                          type="text"
                          name="ssn"
                          className="form-control"
                          id="exampleFormControlInput2"
                          aria-describedby="emailHelp"
                        />
                        <pre className="text--base mt-1">
                          Note: only USA and canadian citizen
                        </pre>
                        <span className="text-danger pt-2">
                          {errorMessage?.ssn && errorMessage?.ssn?.msg}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="user-submit-btn">
                    <button
                      type="submit"
                      id="submit"
                      className="btn btn-primary px-5 fw-normal d-block mt-1"
                      ref={refSubmitDis}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default KYC;
