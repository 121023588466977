import React from 'react';
import dateFormat from 'dateformat';
const SingleItem = ({ data }) => {
    return (
        <>
            <tr>
                <td>{data?.NetworkType}</td>
                <td>
                    {dateFormat(data?.createdAt, "dd,")}&nbsp;{dateFormat(data?.createdAt, "mm,")}&nbsp;{dateFormat(data?.createdAt, "yyyy")}
                </td>
                <td>
                    {data?.amount}$
                </td>
                <td>{data?.transaction}</td>
                <td>
                    {data.status === 0 ? <span className="badge bg-warning ">pending</span> : ''}
                    {data.status === 1 ? <span className="badge bg-success ">accept</span> : ''}
                    {data.status === 2 ? <span className="badge bg-danger ">rejected</span> : ''}</td>
            </tr>

        </>
    );
};

export default SingleItem;