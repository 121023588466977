import React, { useContext, useEffect, useRef, useState } from "react";
import "./Loan.css";
import SingleItem from "./SingleItem";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import axios from "axios";
import LoanSingleHistoryItem from "./LoanSingleHistoryItem";
import CustomPagination from "../CustomPagination/CustomPagination";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
const Loan = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [MiningaginateData, setMiningaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/user/loan/package/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setMiningaginateData(data);
        setLoading(false);
      });
  }, []);

  const [filter, setFilter] = useState('');
  
  const handleMiningPage = (event) => {
    try {
      if(event?.target?.value){
        setFilter(event?.target?.value)
      }
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      if(event?.target?.value){
      
      axios
        .get(
          `${process.env.REACT_APP_API}/api/user/loan/package/filter/view?&page=1&filter=${event?.target?.value}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setMiningaginateData(response?.data);
        })
        .catch((error) => {});

      }else{
        axios
          .get(
            `${process.env.REACT_APP_API}/api/user/loan/package/filter/view?page=${pageNumber}&filter=${filter}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setMiningaginateData(response?.data);
          })
          .catch((error) => {});

      }

    } catch (error) {
      console.log(error);
    }
  };


  // Handle submit form
  const [updateData, setUpdateData] = useState([]);
  const refSubmitDis = useRef();
  const [errorMessage, setErrorMessage] = useState([]);

  const navigate = useNavigate();
  

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    setErrorMessage();
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_API}/api/user/loan/amount/check`,
          { user_id: authUser?._id, ...InpuData },
          config
        )
        .then((data) => {
          setUpdateData(data?.data);
          const packageId = data?.data?.data?._id;
          if(packageId){
            const Location =  `/user/loan/confirm/${data?.data?.amount}/${packageId}`;
            navigate(Location, { replace: true });
            refSubmitDis.current.removeAttribute("disabled");
            event.target.reset();
          }
        })
        .catch((error) => {
          setErrorMessage({ id: InpuData?.id, ...error?.response?.data });
          refSubmitDis.current.removeAttribute("disabled");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [loanHistory, setLoanHistory] = useState([]);
  const [loanHistoryPaginateData, setLoanHistoryPaginateData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/loan/history/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoanHistory(data.data);
          setLoanHistoryPaginateData(data);
          setLoading(false);
        });
    }
  }, [authUser?._id, updateData]);

  const handleloanHistoryPage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API}/api/user/loan/history/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setLoanHistory(response?.data?.data);
          setLoanHistoryPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  if(loading){
    return;
  }
  return (
    <>
      <section className="user-loan-area">
        <div className="container col-12 text-right py-2">
        <div className="order-filter-area">
          <p><FontAwesomeIcon icon={faFilter}  /> Filter</p>
          <div className="el-select w-full">
            <select
              className="form-select"
              aria-label="Default select example"
              name="filter"
              onChange={handleMiningPage}
            >
              <option selected value="createdAt">
              Newest 
              </option>
              <option value="-createdAt">Older </option>
              
            </select>
          </div>

        </div>
          <div className="row g-4 justify-content-center">
            {data?.length !== 0 ? (
              data.map((data, index) => {
                if (data) {
                  return (
                    <SingleItem
                      data={data}
                      index={index}
                      key={data._id}
                      handleSubmitData={handleSubmitData}
                      refSubmitDis={refSubmitDis}
                      errorMessage={errorMessage}
                    ></SingleItem>
                  );
                }
              })
            ) : (
              <>
                <div className="text-center h-50">
                  <h6> Data not found</h6>
                </div>
              </>
            )}
      {/* paginateLinks */}
      <CustomPagination
                    data={MiningaginateData}
                    handlePage={handleMiningPage}
                  ></CustomPagination>
                  {/* paginateLinks */}
          </div>
       
{/* History  */}
          <div className="user-data-table">
            <div className="col-xxl-12 col-md-12 pt-5">
              <div className=" text-white card ">
                <div className="card-header d-flex flex-wrap justify-content-between">
                  <h4 className="title text-primary">My Loan</h4>
                </div>
                <div className="card-body  text-white p-2">
                  <div className="text-white table-responsive ">
                    <table
                      className="text-white table table-responsive-md "
                    >
                      <thead>
                        <tr
                          className=" mx-auto table-header"
                        >
                          <th>S.N.</th>
                          <th>Loan No. | Plan</th>
                          <th>Amount</th>
                          <th>Installment Amount</th>
                          <th>Installment</th>
                          <th>Next Installment</th>
                          <th>Paid</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loanHistory?.length !== 0 ? (
                          loanHistory.map((data, index) => {
                            if (data) {
                              return (
                                <LoanSingleHistoryItem
                                  data={data}
                                  index={index}
                                  key={data?._id}
                                  paginateData={loanHistoryPaginateData}
                                ></LoanSingleHistoryItem>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td
                              className="text-muted text-center"
                              colSpan="100%"
                            >
                              No records available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* paginateLinks */}
                  <CustomPagination
                    data={loanHistoryPaginateData}
                    handlePage={handleloanHistoryPage}
                  ></CustomPagination>
                  {/* paginateLinks */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Loan;
