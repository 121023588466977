import React from "react";
import UserMenu from "../UserMenu/UserMenu";
import { Outlet } from "react-router-dom";
import "./UserDashboradLayout.css"

const UserDashboradLayout = () => {
  return (
    <>
    <UserMenu />
    <div className="user-dashboard-menu">
    <Outlet />
    </div>
    
    </>
  );
};

export default UserDashboradLayout;
