import React from 'react';
import dateFormat from "dateformat";
const SingleItem = ({data, index}) => {
    return (
        <>
            <p className='pb-3'><span>Time: {dateFormat(data?.t, "yyyy-mm-dd , h:MM:ss TT")}</span>&nbsp;&nbsp;&nbsp; Close:{data?.c}&nbsp;&nbsp;&nbsp;&nbsp; Open:{data?.o}&nbsp;&nbsp;&nbsp;&nbsp;  Hight:{data?.h}&nbsp;&nbsp;&nbsp;&nbsp;  Low: {data?.l} </p>
        </>
    );
};

export default SingleItem;