import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
const SingleItem = ({ data, handleModal }) => {

  const [singleData, setSingleData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(data?.investment_plan_id){
      fetch(`${process.env.REACT_APP_API}/api/user/investment/plan/view/${data?.investment_plan_id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
          setLoading(false);
        });
    }
  }, [data?.investment_plan_id]);

  if(loading){
    return;
  }

  return (
    <>
      <div className="col-sm-12 col-12 col-md-6 col-lg-4 col-xxl-4 col-xl-4">
        <div
          className="pricingTable blue"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <div className="pricingTable-header">
            <h3 className="title">{singleData?.promotion_name} </h3>
          </div>
          <div className="price-value">
            <span>
              {singleData?.percentage}%
              {singleData?.instant === 1 ? "DAILY" : singleData?.period_type}
            </span>
          </div>
          <ul className="pricing-content">
            <li>
              <FontAwesomeIcon icon={faCircleCheck} />{singleData?.period} DAYS
            </li>
            <li>
              <FontAwesomeIcon icon={faCircleCheck} />${singleData?.min_amount} - $
              {singleData?.max_amount}
            </li>
            <li>
              <FontAwesomeIcon icon={faCircleCheck} />Principal
              {singleData?.principal === 1 ? (
                <span> Returns</span>
              ) : singleData?.principal === 2 ? (
                <span> Included</span>
              ) : (
                ""
              )}
            </li>
          </ul>

          <div className="pricingTable-signup">
            <button onClick={() => handleModal(singleData?._id)}>INVEST</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
