import React, { useEffect, useState } from 'react';

const Demo = () => {
    const [data, setData] = useState([]);
  
    useEffect(() => {
        fetch(`https://www.okex.com/api/v1/future_price_limit.do?symbol=btc_usd&contract_type=this_week`, {
            method: "GET",
          })
            .then((res) => res.json())
            .then((data) => {
          });
    }, []);

    return (
        <>
            
        </>
    );
};

export default Demo;