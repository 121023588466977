import React, { useEffect, useRef, memo } from "react";
function Forex() {
  const container = useRef({});
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
           {
  "width": "100%",
  "height": "100%",
  "symbolsGroups": [
    {
      "name": "Forex",
      "symbols": [
        {
          "name": "EURUSD",
          "displayName": "EURUSD"
        },
        {
          "name": "GBPUSD",
          "displayName": "GBPUSD"
        },
        {
          "name": "AUDUSD",
          "displayName": "AUDUSD"
        },
        {
          "name": "USDJPY",
          "displayName": "USDJPY"
        },
        {
          "name": "NZDUSD",
          "displayName": "NZDUSD"
        },
        {
          "name": "USDCHF",
          "displayName": "USDCHF"
        },
        {
          "name": "USDCAD",
          "displayName": "USDCAD"
        },
        {
          "name": "EURAUD",
          "displayName": "EURAUD"
        },
        {
          "name": "GBPJPY",
          "displayName": "GBPJPY"
        },
        {
          "name": "EURCHF",
          "displayName": "EURCHF"
        },
        {
          "name": "EURCAD",
          "displayName": "EURCAD"
        },
        {
          "name": "NZDJPY",
          "displayName": "NZDJPY"
        },
        {
          "name": "CADJPY",
          "displayName": "CADJPY"
        },
        {
          "name": "EURNZD",
          "displayName": "EURNZD"
        },
        {
          "name": "AUDJPY",
          "displayName": "AUDJPY"
        },
        {
          "name": "GBPAUD",
          "displayName": "GBPAUD"
        },
        {
          "name": "AUDNZD",
          "displayName": "AUDNZD"
        },
        {
          "name": "GBPNZD",
          "displayName": "GBPNZD"
        },
        {
          "name": "EURJPY",
          "displayName": "EURJPY"
        },
        {
          "name": "EURGBP",
          "displayName": "EURGBP"
        }
      ]
    }
  ],
"showSymbolLogo": true,
  "isTransparent": false,
  "colorTheme": "dark",
  "locale": "en",
  "backgroundColor": "#0B0619"
}
`;
    container.current.appendChild(script);
  }, []);

  return (
    <>
      <div className="user-market-data">
        <div
          className="tradingview-widget-container"
          ref={container}
          style={{ height: "100%", width: "100%" }}
        >
          <div
            className="tradingview-widget-container__widget"
            style={{ height: "calc(100% - 32px)", width: "100%" }}
          ></div>
        </div>
      </div>
    </>
  );
}

export default memo(Forex);
