import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
const SingleItem = ({ data, handleTradeChart }) => {
  const MarketSymbol = data?.base_currency + data?.quote_currency;
  const [livePrice, setLivePrice] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetch(
      `https://api.polygon.io/v2/snapshot/locale/global/markets/forex/tickers/C:${MarketSymbol}?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.ticker) {
          setLivePrice(data?.ticker);
        }
        setLoading(true);
      });
  }, [MarketSymbol]);
  return (
    <>
      <div
        className="flex py-14 cursor-pointer items-center hover:bg-gray-100 px-10 bottom-border"
        onClick={() =>
          handleTradeChart({
            MarketData: data,
            marketSymbol: MarketSymbol,
            Market: "Forex",
          })
        }
      >
        <div className="flex-1 flex items-center">
          <LazyLoadImage
            alt="img"
            effect="blur"
            className="w-36 h-36"
            src={`${process.env.REACT_APP_API}/${data?.image}`}
          />

          <span className="pl-10">
            {data?.base_currency}
            {data?.quote_currency}
          </span>
        </div>
        {!livePrice?.lastQuote && loading ? (
          <div className="market-close-btn">closed</div>
        ) : (
          <></>
        )}
        {/* <div className="flex-1 text-kline-up">{ !(isNaN(TotalLivePrice)) && TotalLivePrice}</div> */}
        {/* <span className="rounded-8 text-white w-80 text-center py-6 text-12 bg-kline-up">
          +0.21%
        </span> */}
      </div>
    </>
  );
};

export default SingleItem;
