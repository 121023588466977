import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import Market from "../Market/Market";
import "./TradeHistory.css";
import PositionHolding from "./TradeHistory/PositionHolding/PositionHolding";
import PendingOrders from "./TradeHistory/PendingOrders/PendingOrders";
import History from "./TradeHistory/History/History";
import ForexCandlestickCharts from "../TradePage/ForexCandlestickCharts/ForexCandlestickCharts";
import CryptoCandlestickCharts from "../TradePage/CryptoCandlestickCharts/CryptoCandlestickCharts";
import CommoditiesCandlestickCharts from "../TradePage/CommoditiesCandlestickCharts/CommoditiesCandlestickCharts";
const Dashboard = () => {
  const { authUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [TradeData, setTradeData] = useState([]);

  useEffect(() => {
      fetch(`${process.env.REACT_APP_API}/api/user/trade/market/crypto/currency/view`, {
          method: 'GET',
      })
          .then(res => res.json())
          .then(data => {
            const MarketSymbol = (data?.data[0]?.Symbol)+(data?.data[0]?.currency);
            setTradeData({MarketData:data?.data[0], marketSymbol:MarketSymbol, Market:"Crypto"});
            setPageLoading(false);
            
          });


  }, []);

  const handleTradeChart = (data) => {
    setLoading(true);
    setTradeData(data)
    setTimeout(function () {
      setLoading(false);
    }, 800);
  };


  const [historyShow, setHistoryShow] = useState("PositionHolding");

  const handleHistory = (data) => {
    switch (data) {
      case "PositionHolding":
        setHistoryShow("PositionHolding");
        break;
      case "PendingOrders":
        setHistoryShow("PendingOrders");
        break;
      case "History":
        setHistoryShow("History");
        break;

      default:
        break;
    }
  };



const [HistoryLoading, setHistoryLoading] = useState(true);
  const [PositionHoldingData, setPositionHoldingData] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [PositionHoldingData]);

  const fetchData = async () => {
    if (authUser?._id) {
      const data = await fetch(
        `${process.env.REACT_APP_API}/api/user/trade/log/running/history/${authUser?._id}`
      );
      const apiResponse = await data.json();
      if(apiResponse){
      setPositionHoldingData(apiResponse);
      setHistoryLoading(false);
      }
    }
  };


  if(pageLoading){
    return;
  }
  return (
    <>
      <section className="user-dashboard-section">
        <div className="row">
          <div className="col-3">
            <Market handleTradeChart={handleTradeChart} />
          </div>
          {loading ? (
            ""
          ) : (
            <>
            {TradeData?.Market === "Crypto" && <CryptoCandlestickCharts TradeData={TradeData} />}
            {TradeData?.Market === "Forex" && <ForexCandlestickCharts TradeData={TradeData} />}
            {TradeData?.Market === "Commodities" && <CommoditiesCandlestickCharts TradeData={TradeData} />}
            </>
          )}
        </div>
      
      </section>
      <section className="trade-order-history-section">
          <div className="trade-order-history-menu-area">
            <div className="trade-order-history-menu">
              <p
                onClick={() => handleHistory("PositionHolding")}
                className={historyShow === "PositionHolding" ? "active" : ""}
              >
                Position holding
              </p>
              <p
                onClick={() => handleHistory("PendingOrders")}
                className={historyShow === "PendingOrders" ? "active" : ""}
              >
                Pending Orders
              </p>
              <p
                onClick={() => handleHistory("History")}
                className={historyShow === "History" ? "active" : ""}
              >
                History
              </p>
            </div>
            <div className="trade-order-history-menu-dis">
              <p>
              Current Margin: {PositionHoldingData?.TotalMargin}
              </p>
              <p>
                Profit and Loss:
                {PositionHoldingData?.ProfitandLoss >= 0 && <span className="profit-up">{parseFloat(PositionHoldingData?.ProfitandLoss).toFixed(2)}</span>}
                {PositionHoldingData?.ProfitandLoss < 0 && <span className="profit-dwon">{parseFloat(PositionHoldingData?.ProfitandLoss).toFixed(2)}</span>}

              </p>
            </div>
          </div>
          {historyShow === "PositionHolding" && <PositionHolding PositionHoldingData={PositionHoldingData} HistoryLoading={HistoryLoading} />}
          {historyShow === "PendingOrders" && <PendingOrders />}
          {historyShow === "History" && <History />}
        </section>
    </>
  );
};

export default Dashboard;
