import React, { useEffect, useRef, useTransition } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import dateFormat from "dateformat";
const SingleItem = ({ data, handleTradeChart }) => {
  const MarketSymbol = data?.Symbol + data?.currency;
  // const getLivePrice = useRef(null);
  return (
    <>
      <div
        className="flex py-14 cursor-pointer items-center hover:bg-gray-100 px-10 bottom-border"
        onClick={() =>
          handleTradeChart({ MarketData: data, marketSymbol: MarketSymbol, Market:"Crypto" })
        }
      >
        <div className="flex-1 flex items-center">
          <LazyLoadImage
            alt="img"
            effect="blur"
            className="w-36 h-36"
            src={`${process.env.REACT_APP_API}/${data?.image}`}
          />

          <span className="pl-10">
            {data?.Symbol}
            {data?.currency}
          </span>
        </div>
        {/* <div className="flex-1 text-kline-up">
          {!isNaN(getLivePrice?.current) && getLivePrice?.current}
        </div> */}
      </div>
    </>
  );
};

export default SingleItem;
