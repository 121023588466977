import React, {  useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import axios from "axios";
const OrderTPSLModels = (props) => {

  const OrderId = props?.OrderId;
  const ModalCloseOnHide = props.onHide;

  //--------- Set Loss -------------//

  const [LossValue, setLossVale] = useState(0);

  const handleSetLoss = (e) => {
    if (e === "-") {
      const minusValue = parseFloat(parseFloat(LossValue) - 1);
      setLossVale(minusValue);
    } else if (e === "+") {
      const plusValue = parseFloat(parseFloat(LossValue) + 1);
      setLossVale(plusValue);
    } else {
      const value = e.target.value;
      setLossVale(parseFloat(value));
    }
  };

  //--------- Take Profit  -------------//

  const [takeProfitValue, setTakeProfitValue] = useState(0);

  const handleTakeProfit = (e) => {
    if (e === "-") {
      const minusValue = parseFloat(parseFloat(takeProfitValue) - 1);
      setTakeProfitValue(minusValue);
    } else if (e === "+") {
      const plusValue = parseFloat(parseFloat(takeProfitValue) + 1);
      setTakeProfitValue(plusValue);
    } else {
      const value = e.target.value;
      setTakeProfitValue(parseFloat(value));
    }
  };

  const refSubmitDis = useRef();

  const [errorMessage, setErrorMessage] = useState([]);

  const updateOrderTPSL = () => {
    if(OrderId){
        const storeData = {
            set_loss: LossValue,
            take_profit: takeProfitValue,
          };
          setErrorMessage();
          refSubmitDis.current.setAttribute("disabled", true);
          const config = {
            headers: {
              "content-type": "application/json",
            },
          };
          axios
            .post(
              `${process.env.REACT_APP_API}/api/user/trade/log/update/${OrderId}`,
              storeData,
              config
            )
            .then((data) => {
              if (data?.data?.success === true) {
                toast.success(`${data.data.message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                refSubmitDis.current.removeAttribute("disabled");
                ModalCloseOnHide();
              } else {
                toast.error(`${data.data.message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                refSubmitDis.current.removeAttribute("disabled");
              }
            })
            .catch((error) => {
              setErrorMessage(error?.response?.data?.errors);
              if (
                error?.response?.data?.success === false &&
                error?.response?.data?.message
              ) {
                toast.error(`${error?.response?.data?.message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              }
      
              refSubmitDis.current.removeAttribute("disabled");
            });
    }else{
        toast.error(`Something is wrong try again`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
    }
 
  };



  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className=" user-oder-tp-sl-modal user-dashboard-menu-section"
      >
        <Modal.Body>
          <div className="user-dashboard-menu-header pb-2">
            <h5>TP/SL Update</h5>
            <FontAwesomeIcon icon={faXmark} onClick={ModalCloseOnHide} />
          </div>
          <div className="container">
            <section className="user-trade-page">
              <div className="el-select w-full mt-4">
                <div className="text-14 mt-10 trade-dis-btn">Set Loss</div>
                <div className="trade-quantity">
                  <button
                    className="btn "
                    id="minusBtn"
                    onClick={() => handleSetLoss("-")}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    id="quantityInput"
                    className="form-control "
                    value={LossValue}
                    onChange={handleSetLoss}
                  />

                  <button
                    className="btn "
                    id="plusBtn"
                    onClick={() => handleSetLoss("+")}
                  >
                    +
                  </button>
                </div>
                {errorMessage?.set_loss && (
                  <span className="text-danger pt-2">
                    {errorMessage?.set_loss?.msg}
                  </span>
                )}
              </div>
              <div className="el-select w-full mt-4">
                <div className="text-14 mt-10 trade-dis-btn">Take Profit</div>
                <div className="trade-quantity">
                  <button
                    className="btn "
                    id="minusBtn"
                    onClick={() => handleTakeProfit("-")}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    id="quantityInput"
                    className="form-control "
                    value={takeProfitValue}
                    onChange={handleTakeProfit}
                  />

                  <button
                    className="btn "
                    id="plusBtn"
                    onClick={() => handleTakeProfit("+")}
                  >
                    +
                  </button>
                </div>
                {errorMessage?.take_profit && (
                  <span className="text-danger pt-2">
                    {errorMessage?.take_profit?.msg}
                  </span>
                )}
              </div>
              <div className="text-center pt-4">
                <button
                  className="user-investment-modal-btn"
                  ref={refSubmitDis}
                  onClick={updateOrderTPSL}
                >
                  Set TP/SL
                </button>
              </div>
            </section>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderTPSLModels;
