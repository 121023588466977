import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./UserModalMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import LogoutConponent from "../../../Auth/Logout/LogoutConponent";
import Deposit from "../Deposit/Deposit";
import Withdrawal from "../Withdrawal/Withdrawal";
import KYC from "../KYC/KYC";
import Assets from "../Assets/Assets";
import UpdateProfile from "../UpdateProfile/UpdateProfile";
import UpdatePassword from "../UpdatePassword/UpdatePassword";
import DepositHistory from "../Deposit/DepositHistory/DepositHistory";
import WithdrawalHistory from "../Withdrawal/WithdrawalHistory/WithdrawalHistory";
import BillingDetails from "../BillingDetails/BillingDetails";
const UserModalMenu = (props) => {
  const [showDetails, setShowDetails] = useState("Assets");
  const handleShow = (showItem) => {
    setShowDetails(showItem);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="user-dashboard-menu-section"
      >
        <Modal.Body>
          <div className="user-dashboard-menu-header">
            <h5>Menu</h5>
            <FontAwesomeIcon icon={faXmark} onClick={props.onHide} />
          </div>

          <div className="user-dashboard-menu-main-area">
            <div className="row">
              <div className="col-3">
                <div className="user-dashboard-menu-main-left">
                  <p
                    onClick={() => handleShow("Assets")}
                    className={showDetails === "Assets" ? "active" : ""}
                  >
                    Assets
                  </p>
                  <p
                    onClick={() => handleShow("BillingDetails")}
                    className={showDetails === "BillingDetails" ? "active" : ""}
                  >
                    Billing Details
                  </p>
                  <p
                    onClick={() => handleShow("Deposit")}
                    className={showDetails === "Deposit" ? "active" : ""}
                  >
                    Deposit
                  </p>
                  <p
                    onClick={() => handleShow("DepositHistory")}
                    className={showDetails === "DepositHistory" ? "active" : ""}
                  >
                    Deposit History
                  </p>
                  <p
                    onClick={() => handleShow("Withdrawal")}
                    className={showDetails === "Withdrawal" ? "active" : ""}
                  >
                    Withdrawal
                  </p>
                  <p
                    onClick={() => handleShow("WithdrawalHistory")}
                    className={
                      showDetails === "WithdrawalHistory" ? "active" : ""
                    }
                  >
                    Withdrawal History
                  </p>
                  <p
                    onClick={() => handleShow("KYC")}
                    className={showDetails === "KYC" ? "active" : ""}
                  >
                    Real name verification
                  </p>

                  <p
                    onClick={() => handleShow("UpdateProfile")}
                    className={showDetails === "UpdateProfile" ? "active" : ""}
                  >
                    Update Profile
                  </p>
                  <p
                    onClick={() => handleShow("UpdatePassword")}
                    className={showDetails === "UpdatePassword" ? "active" : ""}
                  >
                    Change Password
                  </p>
                  <p
                    onClick={() => handleShow("")}
                    className={showDetails === "" ? "active" : ""}
                  >
                    Invite Friends
                  </p>
                  <p>
                    <a
                      href="/live/support"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Online service
                    </a>
                  </p>
                  {/* Logout  section*/}
                  <LogoutConponent />
                </div>
              </div>
              <div className="col-9">
                <div className="modal-body-details-area">
                  {showDetails === "Billing" && <>Billing Details</>}
                  {showDetails === "Deposit" && (
                    <>
                      <Deposit />
                    </>
                  )}
                  {showDetails === "DepositHistory" && (
                    <>
                      <DepositHistory />
                    </>
                  )}
                  {showDetails === "Withdrawal" && (
                    <>
                      <Withdrawal />
                    </>
                  )}
                  {showDetails === "WithdrawalHistory" && (
                    <>
                      <WithdrawalHistory />
                    </>
                  )}
                  {showDetails === "KYC" && (
                    <>
                      <KYC />
                    </>
                  )}
                  {showDetails === "Assets" && (
                    <>
                      <Assets />
                    </>
                  )}
                  {showDetails === "UpdateProfile" && (
                    <>
                      <UpdateProfile />
                    </>
                  )}
                  {showDetails === "UpdatePassword" && (
                    <>
                      <UpdatePassword />
                    </>
                  )}
                  {showDetails === "BillingDetails" && (
                    <>
                      <BillingDetails />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserModalMenu;
