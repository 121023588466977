import React, { useContext, useEffect, useState } from "react";
import dateFormat from "dateformat";
import image from "./images.png";
import adminImage from "./admin.jpeg";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
const SingleMessageItem = ({ data, ConversationData }) => {
  const { authUser } = useContext(AuthContext);
  const [admin, setAdmin] = useState([]);
  useEffect(() => {
    if (data?.recevier_email !== null) {
      fetch(
        `${process.env.REACT_APP_API}/api/admin/role/view/by/email/${data?.sender_email}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setAdmin(data.data);
        });
    }
  }, [data?.recevier_email, data?.sender_email]);
  if (data?.recevier_email !== null) {
    return (
      <>
        <div className="direct-chat-msg">
          <div className="direct-chat-info">
            <span className="direct-chat-name pull-left">{admin?.name}</span>
            <span className="direct-chat-timestamp pull-right">
              {dateFormat(data?.createdAt, "dd mmm yyyy h:MM TT")}
            </span>
          </div>

          <img
            className="direct-chat-img"
            src={adminImage}
            alt="Message User"
          />
          <div className="direct-chat-text">{data?.message}</div>
        </div>
      </>
    );
  } else {
    if(!data?.attach && !data?.message){
        return;
    }
    return (
      <>
        <div className="direct-chat-msg right">
          <div className="direct-chat-info  d-flex ">
            <span className="direct-chat-timestamp pull-left">
              {dateFormat(data?.createdAt, "dd mmm yyyy h:MM TT")}
            </span>
            <span className="direct-chat-name pull-right">
              {ConversationData?.fname} {ConversationData?.lname}
            </span>
          </div>
          {authUser ? (
            authUser?.picture !== null ? (
              <img
                src={`${authUser?.picture}`}
                alt=""
                width="30px"
                height="30px"
                className="direct-chat-img"
              />
            ) : (
              <img className="direct-chat-img" src={image} alt="Message " />
            )
          ) : (
            <img className="direct-chat-img" src={image} alt="Message " />
          )}
          {data?.attach  &&  <Link
              to={`${process.env.REACT_APP_API}/${data?.attach}`}
              target="_blank"
              className="chat-attach-file"
            >
              <LazyLoadImage
                effect="blur"
                width="100px"
                height="auto"
                src={`${process.env.REACT_APP_API}/${data?.attach}`}
                alt="Message "
              />
            </Link>}
          {data?.message &&<div className="direct-chat-text">{data?.message}</div>}
        </div>
      </>
    );
  }
};

export default SingleMessageItem;
