import React, { useEffect, useRef, memo } from "react";
function Metals() {
  const container = useRef({});
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
           {
  "width": "100%",
  "height": "100%",
  "symbolsGroups": [
    {
      "name": "Stock",
      "symbols": [
         {
          "name": "XAUUSD",
          "displayName": "Gold"
        },
        {
          "name": "XAGUSD",
          "displayName": "Silver"
        },
        {
          "name": "XCUUSD",
          "displayName": "Cupper"
        },
        {
          "name": "XPTUSD",
          "displayName": "Platinum"
        },
        {
          "name": "XPDUSD",
          "displayName": "Palladium"
        },
        {
          "name": "NATGAS",
          "displayName": "Natural GAS"
        },
        {
          "name": "OIL",
          "displayName": "Nymex"
        },
        {
          "name": "WTI",
          "displayName": "USA OIL"
        },
        {
          "name": "UKOIL",
          "displayName": "UK OIL"
        }
      ]
    }
  ],
"showSymbolLogo": true,
  "isTransparent": false,
  "colorTheme": "dark",
  "locale": "en",
  "backgroundColor": "#0B0619"
}
`;
    container.current.appendChild(script);
  }, []);

 

  return (
    <>
      <div className="user-market-data">
        <div
          className="tradingview-widget-container"
          ref={container}
          style={{ height: "100%", width: "100%" }}
        >
          <div
            className="tradingview-widget-container__widget"
            style={{ height: "calc(100% - 32px)", width: "100%" }}
          ></div>
        </div>
      </div>
    </>
  );
}

export default memo(Metals);
