import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../Contexts/AuthContext/AuthProvider";
import SingleItem from "./SingleItem";

const History = () => {
    const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const[loading, setLoading] = useState(true);

  useEffect(() => {
    if(authUser?._id){
        fetch(
            `${process.env.REACT_APP_API}/api/user/trade/log/history/${authUser?._id}`,
            {
              method: "GET",
            }
          )
            .then((res) => res.json())
            .then((data) => {
                setData(data?.data);
                setLoading(false);
            });
    }
  }, [authUser?._id]);

  if(loading){
    return;
  }
  return (
    <>
      <div className="trade-order-history-area">
        <table>
          <thead>
            <tr>
              <th>Pairs</th>
              <th>Transaction</th>
              <th>Direction</th>
              <th>Lots</th>
              <th>Open price</th>
              <th>Close price</th>
              <th>Take Profit</th>
              <th>Set Loss</th>
              <th>Handling fee</th>
              <th>Margin</th>
              <th>Profit</th>
              <th>Open time</th>
            </tr>
          </thead>
          <tbody>
            {data?.length !== 0 ? (
              data.map((data, index) => {
                if (data) {
                  return (
                    <SingleItem
                      data={data}
                      index={index}
                      key={data?._id}
                    ></SingleItem>
                  );
                }
              })
            ) : (
              <tr>
                <td className="text-muted text-center" colSpan="100%">
                  No records available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default History;