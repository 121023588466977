import React, { useState, useEffect, useTransition, useRef } from "react";
import { createChart } from "lightweight-charts";
import { GetCandles, GetLiveCandle, GetAgre } from "./TradeLiveDataFun";
import dateFormat from "dateformat";
import SingleItem from "./SingleItem";
const StockCandlestickCharts = () => {
  // const [currentTimeFrame, updateTimeFrame] = useState({period:"1",period_type:"hour"});
  // const cryptoCoin = `X:BTCUSD`;
  // const [isPending, startTransition] = useTransition();
  // const getLivePrice = useRef(null);
  // const [livePrice, setLivePrice] = useState();
  // const [LiveDataShow, setLiveDataShow] = useState();
  // const chartContainerRef = useRef();
  // var chart = useRef(null);
  // var newSeries = useRef(null);

  // const chartOptions = {
  //   layout: {
  //     textColor: "white",
  //     background: { type: "solid", color: "rgb(11, 6, 25)" },
  //   },
  //   grid: {
  //     vertLines: {
  //       color: "transparent",
  //     },
  //     horzLines: {
  //       color: "transparent",
  //     },
  //   },
  //   width: chartContainerRef.current && chartContainerRef.current.offsetWidth,
  //   height: 600,
  // };

  // useEffect(() => {
  //   chart.current = createChart(chartContainerRef.current, chartOptions);
  //   newSeries.current = chart.current.addCandlestickSeries({
  //     upColor: "#26a69a",
  //     downColor: "#ef5350",
  //     borderVisible: false,
  //     wickUpColor: "#26a69a",
  //     wickDownColor: "#ef5350",
  //   });

  //   // chart.current.timeScale().fitContent();
  //   chart.current.timeScale().scrollToPosition(5);
  //   chart.current.timeScale().applyOptions({
  //     rightOffset: 5,
  //     barSpacing: 10,
  //     minBarSpacing: 5,
  //     fixLeftEdge: true,
  //     borderVisible: false,
  //     timeVisible: true,
  //     tickMarkFormatter: (time, tickMarkType, locale) => {
  //       const date = new Date(parseFloat(time) * 1000);
  //       const timeFormatter = new Intl.DateTimeFormat(locale, {
  //         hour12: true,
  //         hour: "numeric",
  //         minute: "numeric",
  //       });
  //       return timeFormatter.format(date);
  //     },
  //   });

  //   return () => [chart.current.remove()];
  // }, []);

  // //update price chart
  // useEffect(() => {
  //   const conn = new WebSocket(GetLiveCandle(currentTimeFrame, cryptoCoin));
  //   //taken fron priceData.js
  //   startTransition(() => {

  //     conn.onopen = () => {
  //       conn.send(
  //         JSON.stringify({
  //           action: "auth",
  //           params: `${process.env.REACT_APP_POLYGON_API_KEY}`,
  //         })
  //       );
  //     };

  //     conn.onmessage = function (event) {
  //       const data = JSON.parse(event.data)[0];
  //       if (data?.status === "auth_success") {
  //         conn.send(
  //           JSON.stringify({
  //             action: "subscribe",
  //             params: `XAS.BTC-USD`,
  //           })
  //         );
  //       }
   
  //       if (data?.ev === "XAS") {
  //        GetAgre(currentTimeFrame, "X:BTCUSD").then((Resp) => {
  //         const resultsData = Resp?.data?.results;
  //         if(resultsData){
  //           console.log(resultsData[0].t / 1000)
  //           var liveData = data;
  //           let removeSeconds = new Date(resultsData[0]?.t);
  //           removeSeconds.setSeconds(0, 0);
  //           var editLiveData = {
  //             time: resultsData[0].t / 1000,
  //             open: resultsData[0].o,
  //             high: parseFloat(liveData?.h),
  //             low: parseFloat(liveData?.l),
  //             close: parseFloat(liveData?.c),
  //           };
  //           getLivePrice.current = editLiveData.close;
  //           newSeries.current.update(editLiveData);
  //           setLivePrice(getLivePrice.current);
  //           setLiveDataShow(liveData);
  //         }
  //       });

         
  //       }
  //     };
  //     //taken fron priceData.js
  //     GetCandles(currentTimeFrame, cryptoCoin).then((Resp) => {
  //       const resultsData = Resp?.data?.results;
  //       console.log(resultsData)
  //       const sort = resultsData.sort((a, b) => (a.t > b.t) ? 1 : -1)
  //       const candles = sort.map((d) => ({
  //         time: d?.t / 1000,
  //         open: parseFloat(d?.o),
  //         high: parseFloat(d?.h),
  //         low: parseFloat(d?.l),
  //         close: parseFloat(d?.c),
  //       }));
  //       //As web scoket is delayed by 2 seconds, im setting live price to last candle open price
  //       const candleLen = candles.length;
  //       getLivePrice.current = candles[candleLen - 1].close;
  //       newSeries.current.setData(candles);
  //     });
  //   });

  //   return () => {
  //     conn.close();
  //   };
  // }, [currentTimeFrame, cryptoCoin]);


  const [data, SetData ] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [data]);

  const fetchData = async () => {
    const date = new Date();
    let d = new Date();
    d.setDate(d.getDate() - 3);
    // AAPL
    let lastDate = dateFormat(date, "yyyy-mm-dd");
    let preDate = dateFormat(d, "yyyy-mm-dd");
    const url = `https://api.polygon.io/v2/aggs/ticker/C:XAUUSD/range/1/second/${preDate}/${lastDate}?adjusted=true&sort=desc&limit=100&apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        if(data?.results){
          SetData(data?.results)
        }
      });
  };

  return (
    <>
      <div className="container">

      {data?.length !== 0 ? (
              data.map((data, index) => {
                if (data) {
                  return (
                    <SingleItem
                      data={data}
                      index={index}
                      key={data._id}
                    ></SingleItem>
                  );
                }
              })
            ) : (
              <>
                <div className="text-center h-50">
                  <h6> Data not found</h6>
                </div>
              </>
            )}
            
        {/* <h1>{livePrice} </h1> */}
        {/* <div
          ref={chartContainerRef}
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div> */}
      </div>
    </>
  );
};

export default StockCandlestickCharts;
