import React from "react";
import CommoditiesSingleItem from "../Commodities/SingleItem";
import ForexSingleItem from "../Forex/SingleItem";
import CryptoSingleItem from "../Crypto/SingleItem";

const AllMarket = ({
  CryptoData,
  ForexData,
  CommoditiesData,
  CommoditiesLoading,
  handleTradeChart,
}) => {
  if (CommoditiesLoading) {
    return;
  }
  return (
    <>
      <div className="dashboard-trade-market-area  h-700 overflow-auto element">
        {CommoditiesData?.length !== 0 ? (
          CommoditiesData.map((data, index) => {
            if (data) {
              return (
                <CommoditiesSingleItem
                  data={data}
                  index={index}
                  key={data._id}
                  handleTradeChart={handleTradeChart}
                ></CommoditiesSingleItem>
              );
            }
          })
        ) : (
          <></>
        )}

        {CryptoData?.length !== 0 ? (
          CryptoData.map((data, index) => {
            if (data) {
              return (
                <CryptoSingleItem
                  data={data}
                  index={index}
                  key={data._id}
                  handleTradeChart={handleTradeChart}
                ></CryptoSingleItem>
              );
            }
          })
        ) : (
          <></>
        )}

        {ForexData?.length !== 0 ? (
          ForexData.map((data, index) => {
            if (data) {
              return (
                <ForexSingleItem
                  data={data}
                  index={index}
                  key={data._id}
                  handleTradeChart={handleTradeChart}
                ></ForexSingleItem>
              );
            }
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default AllMarket;
