import React, { useContext, useEffect, useRef, useState } from "react";
import "./Deposit.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uploadImg from "./upload.png";
import Swal from 'sweetalert2';
const Deposit = () => {
  const { authUser } = useContext(AuthContext);
  const [GatewaysData, setGatewaysData] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/payment/gateways/manual/view`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setGatewaysData(data?.data);
      });
  }, []);

  const [SingleGatewaysData, setSingleGatewaysData] = useState([]);

  const SingleGatewaysHandle = (event) => {
    const id = event.target.value;
    if (id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/payment/gateways/manual/view/${id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleGatewaysData(data.data);
        });
    }
  };

  const [userImage, setUserImage] = useState("");
  const [UploadImageShow, setUploadImageShow] = useState("");
  const refSubmitDis = useRef();

  const handleImage = (event) => {
    const file = event.target.files[0];
    setUserImage(file);
    getBase64(file);
    // Additional validation logic
  };

  const onLoad = (fileString) => {
    setUploadImageShow(fileString);
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };

  const [errorMessage, setErrorMessage] = useState([]);

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    setErrorMessage();
    if (userImage === "") {
      setErrorMessage({ image: { msg: "screenshot field is required" } });
      refSubmitDis.current.removeAttribute("disabled");
    } else {
      const formData = new FormData(event.currentTarget);
      const InpuData = Object.fromEntries(formData);
      const storeData = {
        ...InpuData,
        user_id: authUser?._id,
        screenshot: userImage,
        network_id: SingleGatewaysData?._id,
      };

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API}/api/user/payment/gateways/manual/deposit/store`,
          storeData,
          config
        )
        .then((data) => {
          if(data?.data?.success){
            event.target.reset();
            setUploadImageShow("");
            toast.success(`${data.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }else{
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${data.data.message}`,
              customClass: {
                popup: 'sweetalert2-body',
                title:"sweetalert2-title",
                htmlContainer:"sweetalert2-htmlContainer",
                confirmButton:"bg-primary"
              }
            });
          }
  

          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          refSubmitDis.current.removeAttribute("disabled");
          if (error?.response?.data?.errors) {
            setErrorMessage(error?.response?.data?.errors);
          } else {
            setErrorMessage(error?.response?.data);
          }
        });
    }
  };
  const handleCopy = () => {
    if (SingleGatewaysData) {
      navigator.clipboard.writeText(SingleGatewaysData?.wallet_address);
      toast.success(`copies wallets address!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.success(`Something is wrong`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [DepositMethodShow, setDepositMethodShow] = useState("Digital");

  return (
    <>
      <div className="user-deposit-section">
        <div className="container">
          <div className="user-deposit-top-btn">
            <button
              onClick={() => setDepositMethodShow("Digital")}
              className={DepositMethodShow === "Digital" ? "active" : ""}
            >
              Digital Currency
            </button>
            <button
              onClick={() => setDepositMethodShow("Bank")}
              className={DepositMethodShow === "Bank" ? "active" : ""}
            >
              Bank Card
            </button>
          </div>
          {DepositMethodShow === "Digital" && (
            <div className="card h-100">
              <div className="card-body">
                <form onSubmit={handleSubmitData}>
                  <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      {/* <h6 className="mb-3 text-primary">Deposit</h6> */}
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Select Network
                        </label>
                        <select
                          name="GatewayId"
                          onChange={SingleGatewaysHandle}
                          className="form-select"
                          required
                        >
                          <option selected disabled>
                            select
                          </option>

                          {GatewaysData.map((data) => {
                            return (
                              <option value={data?._id} key={data?._id}>
                                {data?.networkType}
                              </option>
                            );
                          })}
                        </select>
                        <span className="text-danger pt-2">
                          {errorMessage?.network_id &&
                            errorMessage?.network_id?.msg}
                        </span>
                      </div>
                    </div>

                    {SingleGatewaysData?.wallet_address ? (
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                        <div className="form-group">
                          <div className="gateways-data-area">
                            <div className="deposit-qr-code-area">
                              <div className="deposit-qr-code">
                                <span className="deposit-qr-code__angle deposit-qr-code__angle--top"></span>
                                <LazyLoadImage
                                  alt="img"
                                  effect="blur"
                                  src={`${process.env.REACT_APP_API}/${SingleGatewaysData?.image}`}
                                />
                                <span className="deposit-qr-code__angle deposit-qr-code__angle--bottom"></span>
                              </div>
                              <div className="deposit-scan-me">
                                <h6>Scan Me</h6>
                              </div>
                            </div>

                            <div className="wallet-address">
                              <span>Wallet Address</span>
                              <p>{SingleGatewaysData?.wallet_address}</p>
                              <p
                                onClick={handleCopy}
                                className="wallet-address-copy"
                              >
                                <FontAwesomeIcon icon={faCopy} />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Amount
                        </label>
                        <input
                          type="text"
                          name="amount"
                          className="form-control"
                          id="exampleFormControlInput2"
                          aria-describedby="emailHelp"
                        />
                        <span className="text-danger pt-2">
                          {errorMessage?.amount && errorMessage?.amount?.msg}
                        </span>
                        <span className="text-danger pt-2">
                          {errorMessage?.message && errorMessage?.message}
                        </span>
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Transaction screenshot
                        </label>
                        <br />

                        <div className="image-parent">
                          <div className="image-file-upload">
                            {UploadImageShow ? (
                              <img
                                src={UploadImageShow}
                                alt="upload"
                                className="user-upload-img"
                              />
                            ) : (
                              <img
                                src={uploadImg}
                                alt="upload"
                                className="upload-img"
                              />
                            )}

                            <input
                              type="file"
                              onChange={handleImage}
                              accept=" .jpg,  .jpeg,  .png, "
                            />
                          </div>
                        </div>

                        <br />
                        <span className="text-danger pt-2">
                          {errorMessage?.image && errorMessage?.image?.msg}
                        </span>
                        <pre className="text--base mt-1">
                          Supported mimes: jpg,jpeg,png
                        </pre>
                      </div>
                    </div>
                  </div>
                  <div className="user-submit-btn">
                    <button
                      type="submit"
                      id="submit"
                      className="btn btn-primary px-5 fw-normal d-block mt-1 m-auto"
                      ref={refSubmitDis}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {DepositMethodShow === "Bank" && (
            <>
              <div className="bank-area">
                <p>
                  Please contact with our customer support to get the details.
                  <br />
                  Thank You.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Deposit;
