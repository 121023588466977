import React, { useEffect, useState } from "react";
import "./Market.css";
import Crypto from "./Crypto/Crypto";
import Forex from "./Forex/Forex";
import CFD from "./CFD/CFD";
import Commodities from "./Commodities/Commodities";
import Stocks from "./Stocks/Stocks";
import AllMarket from "./AllMarket/AllMarket";
const Market = ({ handleTradeChart }) => {
  const [marketShow, setMarketShow] = useState("AllMarkets");
  const handleMarketShow = (market) => {
    if(market === "Select"){
      return;
    }
    setMarketShow(market);
  };

  const [CommoditiesData, setCommoditiesData] = useState([]);
  const [CommoditiesLoading, setCommoditiesLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/trade/market/commodities/view`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCommoditiesData(data.data);
        setCommoditiesLoading(false);
      });
  }, []);

  const [CryptoData, setCryptoData] = useState([]);
  const [CryptoLoading, setCryptoLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/trade/market/crypto/currency/view`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCryptoData(data.data);
        setCryptoLoading(false);
      });
  }, []);

  const [ForexData, setForexData] = useState([]);
  const [ForexLoading, setForexLoading] = useState(true);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/user/trade/market/forex/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setForexData(data.data);
        setForexLoading(false);
      });
  }, []);

  return (
    <>
      <section className="dashboard-trade-market-section">
        <div className=" box-shadow ml-10 rounded-10 min-w-350 w-350">
          <div className="p-10 flex">
            <div className="base-input w-full">
              <i className="el-icon ml-16 mr-10" style={{ fontSize: "20px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                  <path
                    fill="currentColor"
                    d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704z"
                  ></path>
                </svg>
              </i>
              <input className="pr-20" />
            </div>
            <div className="el-select trade-type ml-10">
              <div className="select-trigger el-tooltip__trigger el-tooltip__trigger">
                <div className="el-input el-input--suffix">
                  <div className="el-input__wrapper">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onClick={(e) => handleMarketShow(e?.target?.value)}
                    >
                      <option defaultValue="AllMarkets" selected disabled>
                        Select
                      </option>
                      {/* <option selected disabled>Select</option> */}
                      <option defaultValue="Forex">Forex</option>
                      {/* <option defaultValue="Stocks">Stocks</option> */}
                      <option defaultValue="Commodities">Commodities</option>
                      {/* <option defaultValue="CFDs">CFDs</option> */}

                      <option defaultValue="Crypto">Crypto</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {marketShow === "AllMarkets" && (
            <AllMarket
              handleTradeChart={handleTradeChart}
              CryptoData={CryptoData}
              ForexData={ForexData}
              CommoditiesData={CommoditiesData}
              CommoditiesLoading={CommoditiesLoading}
            />
          )}
          {marketShow === "Crypto" && (
            <Crypto
              handleTradeChart={handleTradeChart}
              CryptoData={CryptoData}
              CryptoLoading={CryptoLoading}
            />
          )}
          {marketShow === "Forex" && (
            <Forex
              handleTradeChart={handleTradeChart}
              ForexData={ForexData}
              ForexLoading={ForexLoading}
            />
          )}
          {marketShow === "Stocks" && (
            <Stocks handleTradeChart={handleTradeChart} />
          )}
          {marketShow === "Commodities" && (
            <Commodities
              handleTradeChart={handleTradeChart}
              CommoditiesData={CommoditiesData}
              CommoditiesLoading={CommoditiesLoading}
            />
          )}
          {marketShow === "CFDs" && <CFD handleTradeChart={handleTradeChart} />}
        </div>
      </section>
    </>
  );
};

export default Market;
