import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Aos from "aos";
import "aos/dist/aos.css"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { isMobile } from 'react-device-detect';

import MainMaster from "./Components/Layouts/MainMaster";
import Home from "./Components/Home/Home";
import LoginForm from "./Components/Auth/LoginForm/LoginForm";
import SignUpForm from "./Components/Auth/SignUpForm/SignUpForm";
import About from "./Components/Pages/About/About";
import ContactUs from "./Components/Pages/ContactUs/ContactUs";
import Benefits from "./Components/Pages/Benefits/Benefits";
import Forex from "./Components/Pages/Forex/Forex";
import Mission from "./Components/Pages/Mission/Mission";
import Security from "./Components/Pages/Security/Security";
import Jobs from "./Components/Pages/Jobs/Jobs";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy/PrivacyPolicy";
import RiskDisclosure from "./Components/Pages/RiskDisclosure/RiskDisclosure";
import Dashboard from "./Components/Pages/User/Dashboard/Dashboard";
import UserPrivateRoute from "./Route/UserPrivateRoute";
import LoginRoute from "./Route/LoginRoute";
import AllMarket from "./Components/Pages/User/AllMarket/AllMarket";
import UserDashboradLayout from "./Components/Pages/User/UserDashboradLayout/UserDashboradLayout";
import BitcoinMining from "./Components/Pages/User/Mining/BitcoinMining/BitcoinMining";
import GoldMining from "./Components/Pages/User/Mining/GoldMining/GoldMining";
import InvestmentPlan from "./Components/Pages/User/InvestmentPlan/InvestmentPlan";
import Loan from "./Components/Pages/User/Loan/Loan";
import LoanConfirmPage from "./Components/Pages/User/Loan/LoanConfirmPage/LoanConfirmPage";
import NewsPage from "./Components/Pages/User/NewsPage/NewsPage";
import NewsDetails from "./Components/Pages/User/NewsPage/NewsDetails";
import StockCandlestickCharts from "./Components/Pages/User/StockCandlestickCharts/StockCandlestickCharts";
import Demo from "./Components/Pages/Demo/Demo";
import LiveSupport from "./Components/Pages/User/LiveSupport/LiveSupport";


function App() {

  useEffect(()=>{
    Aos.init({duration:1000});
    },[]);
  useEffect(()=>{
    if(isMobile){
      window.location.href = process.env.REACT_APP_MOBILE_FRONTEND;
    return null;
    }
    },[]);
  return (
    <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainMaster />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginRoute > <LoginForm /> </LoginRoute>} />
          <Route path="/signup" element={<LoginRoute > <SignUpForm />  </LoginRoute> } />
          <Route path="/about/us" element={<About />} />
          <Route path="/contact/us" element={<ContactUs />} />
          <Route path="/benefits" element={<Benefits />} />
          <Route path="/forex" element={<Forex />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/security" element={<Security />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/privacy/policy" element={<PrivacyPolicy />} />
          <Route path="/risk/disclosure" element={<RiskDisclosure />} />
        </Route>
        <Route path="/user/" element={<UserPrivateRoute > <UserDashboradLayout /> </UserPrivateRoute>}>
          <Route path="trade" element={<Dashboard />} />
          <Route path="all/market" element={<AllMarket />} />

          {/* mining  */}
          <Route path="bitcoin/mining" element={<BitcoinMining />} />
          <Route path="gold/mining" element={<GoldMining />} />
          <Route path="investment/plan" element={<InvestmentPlan />} />
          <Route path="apply/loan" element={<Loan />} />
          <Route path="loan/confirm/:amount/:id" element={<LoanConfirmPage />} />
          <Route path="news" element={<NewsPage />} />
          <Route path="news/details/:id" element={<NewsDetails />} />
          <Route path="stocks" element={<StockCandlestickCharts />} />
          <Route path="demo" element={<Demo />} />
        </Route>
        <Route path="/live/support" element={<LiveSupport />} />
      </Routes>
    </BrowserRouter>
    
    </>
   
  );
}

export default App;
