import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTelegram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
const ContactUs = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}/api/user/social/support/view`
      )
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        // console.log("API call error:", err?.message);
      });
  }, []);

  return (
    <>
    <section className="contact-us-section">
    <div className="contact-us">
        <div className="x-contact x__width">
          <div className="x-contact__headings">
            <h2 className="x-contact__headings-subheading">
              <span>24/7 live chat Support</span>
            </h2>
            <h1 className="x-contact__headings-heading">
              <span>Contact us</span>
            </h1>
          </div>
          <div className="x-contact__container">
            <div className="x-contact__txt">
              <p className="x-contact__txt-txt1" >
                <svg
                  className="x-contact__txt-txt1-icon"
                  role="presentation"
                  viewBox="0 0 27 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink" 
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <path
                      d="M26.4606466,22.73473 L14.2670983,0.416534778 C13.9631305,-0.138844926 13.0372918,-0.138844926 12.7333241,0.416534778 L0.539775671,22.73473 C0.394324058,23.0008316 0.4012918,23.3227286 0.558936961,23.581963 C0.716582122,23.8411975 1.00051761,24 1.30709825,24 L25.694195,24 C26.0007757,24 26.2847112,23.8411975 26.4414853,23.581963 C26.5991305,23.3218702 26.6060983,23.0008316 26.4606466,22.73473 Z"
                      id="Shape"
                      fill="#FF2626"
                    ></path>
                    <path
                      d="M14.9910968,7.71428571 L14.3692258,16.2857143 L12.6272903,16.2857143 L12.0054194,7.71428571 L14.9910968,7.71428571 Z M13.4982581,21.4285714 C12.6429677,21.4285714 11.9496774,20.7462857 11.9496774,19.9045714 C11.9496774,19.0628571 12.6429677,18.3805714 13.4982581,18.3805714 C14.3535484,18.3805714 15.0468387,19.0628571 15.0468387,19.9045714 C15.0468387,20.7462857 14.3535484,21.4285714 13.4982581,21.4285714 Z"
                      fill="#FFFFFF"
                    ></path>
                  </g>
                </svg>
                Rubicon Liberty Group will never ask for sensitive information,including passwords,12-word 
              </p>
              <p className="x-contact__txt-txt2">
              Phrases or private keys.

              </p>
            </div>
            <div className="x-contact__links">
              <div className="x-contact__links-row" data-aos="zoom-in-up" data-aos-duration="2000">
                <a
                  href={data?.tele_link}
                  title="Twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="x-contact__links-item x-contact__links-item--twitter"
                >
                  <span className="x-contact__links-item-container">
                  <FontAwesomeIcon icon={faTelegram} />
                    <span className="x-contact__links-item-label"> Telegram </span>
                  </span>
                </a>
                <a
                  href={data?.whatapp_link}
                  title="Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="x-contact__links-item x-contact__links-item--facebook"
                >
                  <span className="x-contact__links-item-container">
                  <FontAwesomeIcon icon={faWhatsapp} />
                    <span className="x-contact__links-item-label">Whatsapp</span>
                  </span>
                </a>
              </div>
              <div className="x-contact__links-row" data-aos="zoom-in-up" data-aos-duration="2000">
                <a
                  href=""
                  className="x-contact__links-item x-contact__links-item--email"
                  title="support@rubicon.com"
                >
                  <span className="x-contact__links-item-container">
                  <FontAwesomeIcon icon={faEnvelope} />
                    <span className="x-contact__links-item-label">
                      support@rubicon.com
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      
    </>
  );
};

export default ContactUs;
