import React, {  useState } from "react";
import SingleItem from "./SingleItem";
import { toast } from "react-toastify";
import OrderTPSLModels from "./OrderTPSLModels";

const PositionHolding = ({ PositionHoldingData, HistoryLoading }) => {
  const { data } = PositionHoldingData;
  const [DisId, setDisId] = useState(null);

  const HandleClose = (id) => {
    setDisId(id);
    if (id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/trade/log/close/possition/${id}`,
        {
          method: "PUT",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.success) {
            toast.error(`${data?.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    }
  };

  const [Modalshow, setModalshow] = useState(false);
  const [OrderId, setOrderId] = useState(null);

  const handleOrderTPSL = (id) => {
    setOrderId(id);
    setModalshow(true);
  };

  if(HistoryLoading){
    return;
  }

  return (
    <>
      {Modalshow && (
        <>
          <OrderTPSLModels
            className="posce-trade-modal"
            show={Modalshow}
            OrderId={OrderId}
            onHide={() => setModalshow(false)}
          />
        </>
      )}

      <div className="trade-order-history-area">
        <table>
          <thead>
            <tr>
              <th>Pairs</th>
              <th>Transaction</th>
              <th>Direction</th>
              <th>Lots</th>
              <th>Open price</th>
              <th>Current price</th>
              <th>Take Profit</th>
              <th>Set Loss</th>
              <th>Handling fee</th>
              <th>Margin</th>
              <th>Profit</th>
              <th>Open time</th>
              <th></th>
              <th>Operation</th>
            </tr>
          </thead>
          <tbody>
            {data?.length !== 0 ? (
              data.map((data, index) => {
                if (data) {
                  return (
                    <SingleItem
                      data={data}
                      index={index}
                      key={data?._id}
                      HandleClose={HandleClose}
                      DisId={DisId}
                      handleOrderTPSL={handleOrderTPSL}
                    ></SingleItem>
                  );
                }
              })
            ) : (
              <tr>
                <td className="text-muted text-center" colSpan="100%">
                  No records available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PositionHolding;
