import React, { useContext, useEffect, useState } from "react";
import "./BillingDetails.css";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import axios from "axios";
import CustomPagination from "../CustomPagination/CustomPagination";
import SingleItems from "./SingleItems";
const BillingDetails = () => {
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [UserData, setUserData] = useState([]);
  const [FreezeData, setFreezeData] = useState([]);
  const [RecordData, setRecordData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/transaction/billing/details/view/${authUser?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setUserData(data?.userData);
        setFreezeData(data?.FreezeBalance);
        setRecordData(data?.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API}/api/user/transaction/billing/details/view/${authUser?._id}/?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setRecordData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return;
  }
  return (
    <>
      <section className="billing-details-area">
        <div className="container">
          <h4>USD</h4>
          <div className="balance-area">
            <div className="row">
              <div className="col-6">
                <div className="user-balance-area">
                  <p>Available</p>
                  <span>{parseFloat(UserData?.balance).toFixed(2)}</span>
                </div>
              </div>
              <div className="col-6">
                <div className="user-freeze-balance-area">
                  <p>Freeze</p>
                  <span>{FreezeData}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="records-area">
            <h6>Financial Records</h6>
            <div className="records-items-lists">
              {RecordData?.length !== 0 ? (
                RecordData.map((data, index) => {
                  if (data) {
                    return (
                      <SingleItems
                        data={data}
                        index={index}
                        key={data?._id}
                        paginateData={paginateData}
                      ></SingleItems>
                    );
                  }
                })
              ) : (
                <tr>
                  <td className="text-muted text-center" colSpan="100%">
                    No records available
                  </td>
                </tr>
              )}

              {/* paginateLinks */}
              <CustomPagination
                data={paginateData}
                handlePage={handlePage}
              ></CustomPagination>
              {/* paginateLinks */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BillingDetails;
