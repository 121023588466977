import React, { useEffect, useState } from "react";

const CalculatorData = ({ data }) => {
  const [Miningdata, setMiningdata] = useState([]);

  useEffect(() => {
    if (data?.mining_id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/mining/view/${data?.mining_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setMiningdata(data?.data);
        });
    }
  }, [data?.mining_id]);
  return (
    <>
      <option key={Miningdata?._id} value={Miningdata?._id}>
        {Miningdata.promotion_name}
      </option>
    </>
  );
};

export default CalculatorData;
