import React from "react";
import dateFormat from "dateformat";
const SingleItem = ({ data, HandleClose, DisId }) => {
  console.log(DisId === data?._id)
  return (
    <>
      <tr>
        <td>{data?.pairs}</td>
        <td>{data?.transaction}</td>
        <td>
          {data?.direction === "Buy" && (
            <p className="trade-order-history-buy-btn">Buy</p>
          )}
          {data?.direction === "Sell" && (
            <p className="trade-order-history-sell-btn">Sell</p>
          )}
        </td>
        <td>{data?.lots}</td>
        <td>{!(isNaN(data?.lower_unit_price)) && parseFloat(data?.lower_unit_price).toFixed(5)}</td>
        <td>{!(isNaN(data?.current_price)) && parseFloat(data?.current_price).toFixed(5)}</td>
        <td>{data?.take_profit === null ? 0 : data?.take_profit}</td>
        <td>{data?.set_loss === null ? 0 : data?.set_loss}</td>
        <td>{data?.handling_fee}</td>
        <td>
          {parseFloat(data?.profit) >= 0 && (
            <p className="trade-order-history-profit-up">{data?.profit}</p>
          )}
          {parseFloat(data?.profit) < 0 && (
            <p className="trade-order-history-profit-dwon">{data?.profit}</p>
          )}
        </td>
        <td>
          {dateFormat(data?.createdAt, "dd-mm-yyyy")}
          <br />
          {dateFormat(data?.createdAt, " h:MM TT")}
        </td>
        <td>
          {DisId === data?._id ?
            <button  className="trade-order-history-close-btn" disabled>
            Close Order
          </button>
        :
        <button onClick={()=>HandleClose(data?._id)} className="trade-order-history-close-btn">
        Close Order
      </button>
        }
        
        </td>
      </tr>
    </>
  );
};

export default SingleItem;
